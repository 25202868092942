<template>
  <!-------------------------------------->
  <!-- Pagination card                  -->
  <!-------------------------------------->
  <div
    style="width: 100%"
       class="d-flex mt-6 flex-column">
    <v-card
      v-for="pag in ($vuetify.breakpoint.xs ? 2 : 1)"
      :key="'pagination-' + pag"
      height="80"
      class="rounded-lg d-flex align-center px-4 mb-12 problem-card"
      flat
      style="position: relative; max-width: 100%; width: 100%"
    >
      <div
        v-if="!$vuetify.breakpoint.xs || pag === 2"
        class="col-4 pa-0">
        <div class="justify-start align-start d-flex">

          <!------------------------>
          <!-- LEFT PROBLEM       -->
          <!------------------------>
          <v-btn
            v-if="allProblemsIds.indexOf(problem['problem_id']) > 0"
            @click="anteriorExercici()"
            class="text-none font-rubik pl-0"
            text
            color="primary">
            <v-icon icon class="mr-2">{{ mdiChevronLeft }}</v-icon>
            <span style="font-size: 16px; letter-spacing: normal"
            >
              Anterior exercici
            </span>
          </v-btn>

        </div>

      </div>
      <v-spacer></v-spacer>

      <!------------------------>
      <!-- SHOW SOLUTION      -->
      <!------------------------>
      <div
        class="pa-0 justify-center text-center align-center d-flex"
        :class="$vuetify.breakpoint.xs ? '' : 'col-4'"
      >
        <v-btn
          v-if="!showSolution && (!$vuetify.breakpoint.xs || pag === 1)"
          @click="onClickSolution"
          class="px-12 text-none font-weight-bold font-rubik rounded-lg white--text solution-button"
          depressed
          height="46"
          color="primary"
        >
          Mostrar la solució
        </v-btn>
      </div>

      <v-spacer></v-spacer>


      <div class="col-4 pa-0"
           v-if="!$vuetify.breakpoint.xs || pag === 2"
      >
        <div class="justify-end align-end d-flex"
        >
          <!------------------------>
          <!-- RIGHT PROBLEM         -->
          <!------------------------>
          <v-btn
            v-if="allProblemsIds.indexOf(problem['problem_id']) < allProblemsIds.length - 1"
            @click="seguentExercici"
            class="text-none font-rubik pr-1"
            text
            color="primary">
              <span style="font-size: 16px; letter-spacing: normal"
              >
              Següent exercici
              </span>
            <v-icon icon class="ml-2">{{ mdiChevronRight }}</v-icon>
          </v-btn>

        </div>
      </div>
    </v-card>

  </div>
</template>

<script>
import {mdiChevronLeft} from '/src/assets/mdi.json'
import {mdiChevronRight} from '/src/assets/mdi.json'

export default {
  name: "ProblemPagination",
  props: {
    problem: {
      required: true
    },
    allProblemsIds: {
      required: true
    },
    showSolution: {
      required: true
    },
  },
  data() {
    return {
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'ArrowLeft' && this.allProblemsIds.indexOf(this.problem['problem_id']) > 0) {
        this.anteriorExercici();
      } else if (event.key === 'ArrowRight' && this.allProblemsIds.indexOf(this.problem['problem_id']) < this.allProblemsIds.length - 1) {
        this.seguentExercici();
      }
    },
    onClickSolution() {
      this.$emit('update:showSolution', true)
    },
    anteriorExercici() {
      this.$emit('toLeft')
      this.$emit('update:showSolution', false)
      window.scroll(0, 0)
    },
    seguentExercici() {
      this.$emit('toRight')
      this.$emit('update:showSolution', false)
      window.scroll(0, 0)
    }
  }
}
</script>

<style scoped>

.problem-card {
  border-radius: 0.5rem;
  box-shadow: 0 .25rem 1rem 0 rgba(48, 53, 69, .08) !important;
}


.solution-button {
  font-size: 16px;
  letter-spacing: normal
}

.solution-button:hover {
  box-shadow: 0px 2px 4px rgba(188, 198, 212, 0.14), 0px 3px 4px rgba(188, 198, 212, 0.12), 0px 1px 5px rgba(188, 198, 212, 0.2) !important;
  background-color: #FFA201;
}
</style>
