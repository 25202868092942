<template>

    <div v-if="$root.currentUser === null || !$root.currentUser.premium">
        <!-------------------------------------->
        <!-- Adsense Right Column             -->
        <!-------------------------------------->
        <v-sheet
            v-if="$root.currentUser === null || !$root.currentUser.premium"
            class="rounded-lg mt-2 justify-start align-start"
            style="position: relative !important; flex: 1; max-height: fit-content"
            color="transparent"
        >

        <div v-if="showAd" style="z-index: 100" class="mt-10">
                <div
                    v-if="selectedProblem % 2 === 0 || true"
                    style="z-index: 99; position: relative"
                    class="rounded-lg">

                    <v-btn @click="showPremium=true"
                           style="position: absolute; right: 10px; top: 10px; z-index: 10"
                           depressed
                           width="30" min-width="30" min-height="30" height="30"
                    >
                        <v-icon size="20">{{ mdiClose() }}</v-icon>
                    </v-btn>

                    <v-carousel
                        @click="$router.push({path: `/dossiers-selectivitat`})"
                        hide-delimiters
                        cycle
                        :show-arrows="false"
                        class="rounded-lg"
                        style="width: 100%; height: 100%; cursor: pointer"
                        :interval="30000"
                        height="auto"
                    >
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/dossiers-sele1.png"></v-carousel-item>
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/Screenshot 2023-05-11 at 22.25.17.png"></v-carousel-item>
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/Screenshot 2023-05-11 at 22.25.53.png"></v-carousel-item>
                    </v-carousel>

                </div>

                <v-img
                    v-else
                    @click="$router.push({path: '/premium'})"
                    class="rounded-lg mt-5"
                    height="100%" style="cursor: pointer"
                    src="https://examenselectivitat.cat:3000/api/images/other/no_ads.png"></v-img>


                <v-icon
                    v-if="false"
                    @click="showAd=false"
                    style="position: absolute; top: 15px; right: 15px; z-index: 99"
                    color="white" small
                >
                    {{ mdiClose() }}
                </v-icon>
            </div>



            <!-- v-if="showAd && (selectedAd % 2 === 1)" -->
            <v-card
                v-if="showAd"
                :key="`adsense_ad_vertical_${selectedAd}`"
                class="my-2 rounded-lg"
                height="250" style="z-index: 99"
                width="300" flat color="transparent"
            >

                <adsense ad-slot="6844584728"
                         style="display:inline-block;width:300px;height:250px;z-index: 99"
                ></adsense>
<!--                <adsense ad-slot="6844584728"-->
<!--                         style="display:inline-block;z-index: 99"-->
<!--                ></adsense>-->

            </v-card>



            <div
                v-else-if="showAd && false"
                style="z-index: 99; position: relative"

            >

                <div
                    style="position: absolute; bottom: 40px;; left: 0; width: 100%; z-index: 10"
                    class="d-flex flex-column justify-center align-center text-center">
                        <span
                        v-if="false"
                            style="z-index: 10; color: white; text-shadow: -1px -1px 0 #1d1d1d, 1px -1px 0 #1d1d1d, -1px 1px 0 #1d1d1d, 1px 1px 0 #1d1d1d; -webkit-font-smoothing: antialiased;"
                            class="font-rubik"
                        >
                            Dut a terme per l'equip d'examenselectivitat</span>
                </div>

                <v-btn @click="showPremium=true"
                       depressed
                       style="position: absolute; right: 10px; top: 10px; z-index: 10"
                       width="30" min-width="30" min-height="30" height="30"
                >
                    <v-icon size="20">{{ mdiClose() }}</v-icon>
                </v-btn>


                <a href="https://crewly.es/"
                   target="_blank"
                   @click="clickStats(4)"
                   class="rounded-lg">

                    <v-carousel
                        hide-delimiters
                        cycle
                        :show-arrows="false"
                        class="rounded-lg mt-2"
                        style="width: 100%; height: 100%; cursor: pointer"
                        :interval="40000"
                        height="auto"
                    >
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-1.webp"></v-carousel-item>
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-2.webp"></v-carousel-item>
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-3.webp"></v-carousel-item>
                        <v-carousel-item
                            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-4.webp"></v-carousel-item>
                    </v-carousel>

                </a>
            </div>



            <v-hover v-slot:default>

            <v-card
                border="left"
                color="white"
                style="cursor:pointer; outline: 2px solid #F44336; z-index: 99"
                dark flat
                class="d-flex flex-column align-center justify-center text-center py-6 rounded-lg mt-3"
            >
          <span
              class="font-rubik font-weight-medium black--text"
              style="font-size: 14px"
          >
            Estudia sense anuncis <br>amb un únic pagament de
          </span>

          <div class="d-flex align-center justify-center text-center">
            <span v-if="$premium.offer" style="color: #FF3B30; margin-right: 4px">-{{ $premium.discountPercentage }}%</span>
            <span class="font-weight-bold ml-1 black--text" style="font-size: 1.2rem">{{ $premium.currentPrice }}€</span>
        </div>
        <div v-if="$premium.offer">
            <span style="font-size: 12px; color: #565959;">Preu habitual:</span>
            <span style="text-decoration: line-through; font-size: 12px; color: #565959; margin-left: 8px;">{{ $premium.regularPrice }}€</span>
        </div>
                    <v-btn
                        @click="$router.push({path: '/premium'})"
                        :ripple="false" depressed
                        color="red"
                        style="letter-spacing: normal;"
                        class="text-none font-weight-bold font-rubik rounded-lg px-3 mt-3"
                    >
                        Veure promoció
                    </v-btn>

                    <countdown-timer v-if="$premium.offer && false" class="mt-5"></countdown-timer>
                </v-card>

            </v-hover>
        </v-sheet>


        <v-dialog v-model="showPremium" max-width="800">
            <v-card style="position: relative; max-width: 800px;"
                    class="pa-10 dialog-bg d-flex"
            >
                <v-btn @click="showPremium=false"
                       style="position: absolute; right: 10px; top: 10px; z-index: 10"
                       width="30" min-width="30" min-height="30" height="30"
                >
                    <v-icon size="20">{{ mdiClose() }}</v-icon>
                </v-btn>
                <!-- Contingut principal -->
                <v-card
                    min-height="300"
                    max-width="550"
                    width="100%"
                    class="rounded-xl pa-8 d-flex flex-column mx-auto"
                    style="background-color: white"
                >
                    <!-- Títol -->
                    <span class="font-weight-bold font-rubik" style="font-size: 1.8rem">
          Necessitem el teu suport
        </span>


                    <p style="color: #333333; font-size: 1rem; margin-top: 10px">
                        Ens esforcem per oferir-te contingut gratuït i de qualitat. Els anuncis ens ajuden a mantenir la web activa i
                        a seguir creixent.
                    </p>
                    <p style="color: #333333; font-size: 1rem; margin-bottom: 30px">
                        Amb el pla <strong style="color: #256EFF">Premium</strong>, no només elimines els anuncis, sinó que contribueixes al
                        manteniment i millora contínua del nostre projecte.
                    </p>

                    <!-- Llistat de beneficis del Premium -->
                    <v-divider class="my-4"></v-divider>
                    <div class="d-flex flex-column" style="min-height: 100px">
                        <div class="d-flex mb-5">
                            <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                            <span class="font-rubik font-weight-medium">Gaudeix de la web <span style="color: #256EFF">sense publicitat</span></span>
                        </div>
                        <div class="d-flex mb-5">
                            <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                            <span class="font-rubik font-weight-medium">Ajuda a garantir el manteniment i <span style="color: #256EFF">millora contínua</span></span>
                        </div>
                        <div class="d-flex mb-5">
                            <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                            <span class="font-rubik font-weight-medium">Dona suport a la creació de nous <span style="color: #256EFF">continguts</span></span>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- Botons d'acció -->
                     <div style="position: relative">
                    <v-btn
                        @click="redirect()"
                        block
                        height="48"
                        style="letter-spacing: normal !important; color: #ffffff"
                        class="justify-center text-none font-rubik font-weight-medium rounded-lg mt-6 mb-2"
                        depressed
                        color="#256EFF"
                    >
                        <!-- Descobreix Premium (4,99€ únic pagament) -->
                        Descobreix Premium (14,99€ únic pagament)

                        <v-spacer></v-spacer>
                        <v-icon>{{ mdiArrowRight() }}</v-icon>
                    </v-btn>

                    <v-chip
                    v-if="$premium.offer"
                     class="ml-2 rounded-lg font-weight-bold" color="red" style="font-size: 12px; position: absolute; right: -20px; top: 4px; z-index: 10">
                        <span style="color: white">-{{ $premium.discountPercentage }}%</span>
                    </v-chip>
                </div>
                    <v-btn text @click="showPremium = false" class="mt-2 text-none justify-start font-rubik font-weight-regular"
                           style="letter-spacing: normal !important;"
                    >Continuar amb anuncis</v-btn>
                </v-card>
            </v-card>

        </v-dialog>


    </div>
</template>

<script>
import Adsense from "../../../../components/anuncis/Adsense.vue";
import {mdiClose, mdiArrowRight, mdiCheck} from "/src/assets/mdi.json";
import Abonaments from "../../../premium/components/Abonaments.vue";
import CountdownTimer from "../../../../components/countdown/CountdownTimer.vue";

export default {
    name: "AnuncisVerticals",
    components: {
        Abonaments,
        Adsense,
        CountdownTimer
    },
    props: {
        color: String,
        selectedProblem: Number
    },
    data() {
        return {
            selectedAd: 0,
            showAd: true,
            examsPeriod: false,
            showPremium: false,
        }
    },
    computed: {
        offerPremium() {
            return this.$premium.offer;
        }
    },
    mounted() {
        this.randomStart = this.selectedAd;
        setInterval(() => {
            this.selectedAd++;
            // if (this.selectedAd > 3) {
            //   this.selectedAd = 0;
            // }
        }, 1000 * 30)
        // }, 1000 *10 )

        // setTimeout(() => {
        //     this.showPremium = true
        // }, 1000 * 60 * 5)
    },
    methods: {
        redirect() {
            this.$router.push({path: "/premium?showLogin=false"})
        },
        async clickStats(ads_id) {
            await this.axios.post("/ad-update/click", {
                ads_id: ads_id
            })
        },
        mdiClose() {
            return mdiClose
        },
        mdiCheck() {
            return mdiCheck
        },
        randomStart() {
            const random = Math.random();
            if (random < 0.25) return 0;
            else if (random < 0.25) return 1;
            else if (random < 0.25) return 2;
            else return 3;
        },
        mdiArrowRight() {
            return mdiArrowRight
        }
    },
    watch: {
        '$refs.video'(val) {
            if (val) {
                this.$refs.video.muted = true;

                this.$refs.video.play();
            }
        },
    }
}
</script>

<style scoped>
.dialog-bg {
    background-color: #e5e5f7;
    background-image: url("https://examenselectivitat.cat:3000/api/images/other/barcelona.webp") !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}
</style>
